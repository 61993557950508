import {
    List,
    Button,
    SearchBar,
    Selector,
    Space,
    DotLoading,
} from 'antd-mobile'
import { DownlandOutline, PlayOutline } from 'antd-mobile-icons'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useParams} from "react-router-dom";
import axios from "axios";
import {Avatar, Pagination} from 'antd';
import {Helmet} from "react-helmet";
import {HomeOutlined} from "@ant-design/icons";

export default () => {
    let { share_id, file_id } = useParams();
    let query = new URLSearchParams(useLocation().search);
    const keyword = query.get('keyword')

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [sortOption, setSortOption] = useState('keyword');
    const [searchKeyWord, setSearchKeyWord] = useState('');
    const [selectorValue, setSelectorValue] = useState('keyword')
    const listDivRef = useRef(null);

    // 页面初始化加载数据
    useEffect(() => {
        fetchData(page, sortOption, searchKeyWord);
    }, []);
    // 获取列表数据
    const fetchData = (page, sort_type, searchKeyWord) => {
        setLoading(true);
        let list_resource_req = {
            share_id: share_id,
            file_id: file_id,
            keyword: keyword,
            page: page,
            sort_type: sort_type,
            search_keyword: searchKeyWord
        }
        axios.post(`/api/list_resource`, list_resource_req)
            .then(response => {
                setData(response.data.files)
                setTotal(response.data.total)
                setPageSize(response.data.page_size)
                setLoading(false);
                listDivRef.current.scrollTop = 0;
            })
            .catch(error => {
                console.error(error);
            });
    };
    const handleDownload = (share_id, file_id) => {
        window.location.href = `/api/download?share_id=${share_id}&file_id=${file_id}`;
    };
    const handlePlayVideo = (share_id, file_id) => {
        window.open(`/mobile/video/${share_id}/${file_id}`);
    };
    const handlePageChange = val => {
        fetchData(val, sortOption, searchKeyWord)
        setPage(val);
    };

    return (
        <>
            <Helmet>
                <meta name="referrer" content="no-referrer" />
                <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
            </Helmet>
            <div style={{display: "flex", flexDirection: "column", height: "100vh", margin: "0 10px"}}>
                <div style={{flex: "0 0 auto"}}>
                    <div style={{ display: 'flex', marginTop: '10px'}}>
                        <HomeOutlined
                            style={{fontSize: 30, color: '#999999', margin: '0 5px'}}
                            onClick={() => {
                                window.location.href = `/mobile/chat`
                            }}
                        />
                        <SearchBar
                            placeholder='输入关键字'
                            style={{
                                '--height': '50px',
                                flexGrow: 1,
                            }}
                            clearable
                            onClear={() => {
                                fetchData(page, sortOption, '')
                                setSearchKeyWord('')
                            }}
                            onSearch={val => {
                                fetchData(1, sortOption, val.trim())
                                setSearchKeyWord(val.trim())
                            }}
                        />
                    </div>

                    <Selector
                        style={{
                            '--border-radius': '10px',
                            '--border': 'solid transparent 1px',
                            '--checked-border': 'solid var(--adm-color-primary) 1px',
                            '--padding': '4px 12px',
                            marginTop: '10px'
                        }}
                        showCheckMark={false}
                        onChange={arr => {
                            if (!arr.length) {
                                return
                            }
                            let value = arr[0]
                            setSelectorValue(value)
                            if (value === "open_share_link") {
                                window.location.href = `https://www.alipan.com/s/${share_id}/folder/${file_id}`;
                                return
                            }
                            fetchData(1, value, searchKeyWord)
                            setPage(1)
                            setSortOption(value)
                        }}
                        options={[
                            {
                                label: '关键字排序',
                                value: 'keyword',
                            },
                            {
                                label: '降序',
                                value: 'number_desc',
                            },
                            {
                                label: '升序',
                                value: 'number_asc',
                            },
                            {
                                label: '打开分享链接',
                                value: 'open_share_link',
                            },
                        ]}
                        value={[selectorValue]}
                        disabled={data.length <= 0}
                    />
                </div>
                <div ref={listDivRef} style={{flex: 1, overflowY: "auto"}}>
                    {
                        loading ?
                            <DotLoading color='currentColor' style={{ display: "flex", justifyContent: "center"}} />
                            : null
                    }
                    {/* 隐藏元素，如果用jsx判断的话，会导致元素被移除，重新加载会有影响 */}
                    <List
                        mode='card'
                        style={{
                            '--padding-left': '0px',
                            '--padding-right': '0px',
                            fontWeight: 'bold',
                            visibility: loading ? "hidden" : "visible"
                        }}
                    >
                        {data.map((item, index) => (
                            <List.Item key={index}>
                                <Space direction='vertical'>
                                    <div>
                                        {item.type === 'folder' ? (
                                            <a href={`/mobile/list/${item.share_id}/${item.file_id}?keyword=${keyword}`}>{item.name}</a>
                                        ) : (
                                            <span>{item.name}</span>
                                        )}
                                    </div>
                                    {item.type === 'file' && (
                                        <Space>
                                            {item.category === "video" ?
                                                <Button
                                                    color='primary'
                                                    style={{
                                                        '--border-radius': '8px',
                                                    }}
                                                    onClick={() =>
                                                        handlePlayVideo(item.share_id, item.file_id)}
                                                >
                                                    <PlayOutline /> <span>观看</span>
                                                </Button> : null}
                                            {/*<Button*/}
                                            {/*    color='primary'*/}
                                            {/*    fill='solid'*/}
                                            {/*    style={{*/}
                                            {/*        '--border-radius': '8px',*/}
                                            {/*    }}*/}
                                            {/*    onClick={() =>*/}
                                            {/*        handleDownload(item.share_id, item.file_id)}*/}
                                            {/*>*/}
                                            {/*    <DownlandOutline /> <span>下载</span>*/}
                                            {/*</Button>*/}
                                        </Space>
                                    )}
                                </Space>
                            </List.Item>
                        ))}
                    </List>
                </div>
                <div style={{flex: "0 0 auto"}}>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Pagination current={page} total={total} pageSize={pageSize} onChange={handlePageChange}
                                    showSizeChanger={false} />
                    </div>
                </div>
            </div>
        </>
    )
}