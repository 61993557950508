import {Button, List, Space, SearchBar, Card, Toast} from 'antd-mobile';
import {LoadingOutlined, ReloadOutlined} from "@ant-design/icons";
import React, {useState, useRef, useEffect} from "react";
import * as Config from "../../config/Config";
import axios from "axios";
import {Helmet} from "react-helmet";
import "./Style.css"

const chatResRecordKey = "chat-resource"

const App = () => {
    const [showCtrlButton, setShowCtrlButton] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState(null);
    const [chatHeight, setChatHeight] = useState("100vh");
    const [data, setData] = useState([]);
    const searchRef = useRef()
    const chatDivRef = useRef(null);
    // 从localStorage加载聊天记录
    useEffect(() => {
        setData(JSON.parse(localStorage.getItem(chatResRecordKey))
            || [getMessageItem(Config.assistantRole, Config.chatResourceGuide)])
    }, []);

    const messageItemStyle = {
        display: "flex",
    }
    const getMessageItem = (role, message, resource_name, index, have_next) => {
        return {
            role: role,
            message: message,
            time: Config.formatDate(),
            resource_name: resource_name ? resource_name : "" ,
            index: index ? index : 0,
            have_next: have_next ? have_next : false
        }
    }
    const sendMessage = (message, index) => {
        if (showCtrlButton) return
        setShowCtrlButton(true)

        const source = axios.CancelToken.source();
        setCancelTokenSource(source);

        let chat_resource_req = {
            message: message,
            index: index
        }
        axios.post(`/api/chat_resource`, chat_resource_req, {
            cancelToken: source.token, // 将取消令牌传递给请求
            timeout: 60 * 1000
        })
            .then(response => {
                handleSaveData(getMessageItem(Config.assistantRole, response.data.message,
                    message, index, response.data.have_next))
                setShowCtrlButton(false)
                // 滚动到底部
                const scrollHeight = chatDivRef.current.scrollHeight;
                const clientHeight = chatDivRef.current.clientHeight;
                chatDivRef.current.scrollTop = scrollHeight - clientHeight;
            })
            .catch(error => {
                setShowCtrlButton(false)
                Toast.show({
                    icon: 'fail',
                    content: '搜索失败',
                })
                console.error(error);
            });
    };
    // 保存聊天记录到localStorage
    const handleSaveData = (message_item) => {
        setData(data => {
            let update_data = [...data, message_item];
            if (update_data.length > Config.recordLength) {
                update_data = update_data.slice(-Config.recordLength)
            }
            localStorage.setItem(chatResRecordKey, JSON.stringify(update_data));
            return update_data;
        });
    }
    const cancelRequest = () => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Request was canceled by the user.');
            setShowCtrlButton(false);
        }
    };
    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
            </Helmet>
            <div style={{display: "flex", flexDirection: "column", height: chatHeight}}>
                <div
                    ref={chatDivRef}
                    style={{
                        flex: 1,
                        overflowY: "auto",
                        display: 'flex',
                        flexDirection: 'column-reverse',
                }}>
                    <List
                        mode='card'
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {data.map((item, index) => (
                            <List.Item
                                key={index}
                                style={ item.role === Config.userRole ? {
                                    justifyContent: "flex-end",
                                    ...messageItemStyle
                                } : {
                                    justifyContent: "flex-start",
                                    ...messageItemStyle
                                }}
                            >
                                <Card
                                    style={ item.role === Config.userRole ? {
                                        backgroundColor: '#DCF8C6',
                                    } : {
                                        backgroundColor: "#F5F5F5"
                                    }}
                                >
                                    {
                                        Array.isArray(item.message) ?
                                            <div>
                                                <span>搜索到资源【按顺序推荐】：</span>
                                                <ol>
                                                    {
                                                        item.message.map((search_info) => (
                                                            <div>
                                                                <a href={search_info.url} target='_blank'>
                                                                    <li>{search_info.name}</li>
                                                                </a>
                                                            </div>
                                                        ))
                                                    }
                                                </ol>
                                            </div> :
                                            <div dangerouslySetInnerHTML={{ __html: item.message }} />
                                    }
                                </Card>
                                {
                                    item.role === Config.assistantRole &&
                                    item.have_next &&
                                    index === data.length - 1 &&
                                    (
                                        <button
                                            style={{
                                                all: 'unset',
                                                fontSize: '12px',
                                                color: '#e35858',
                                            }}
                                            onClick={() => sendMessage(item.resource_name,item.index+1)}
                                            disabled={showCtrlButton}
                                        >
                                            <span><ReloadOutlined /> 不满意，换一个</span>
                                        </button>
                                    )
                                }
                            </List.Item>
                        ))}
                    </List>
                </div>
                <div style={{flex: "0 0 auto"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        {showCtrlButton && (
                            <Button color='primary' onClick={cancelRequest} style={{marginBottom: "10px"}}>
                                <LoadingOutlined />
                                正在搜索资源，点击停止...
                            </Button>
                        )}
                        <div style={{width: "95%"}} >
                            <div style={{marginRight: "auto", marginBottom: "10px"}}>
                                <Button
                                    color='primary'
                                    fill='outline'
                                    size='mini'
                                    style={{
                                        borderRadius: "8px"
                                    }}
                                    onClick={() => {
                                        if (showCtrlButton) {
                                            return
                                        }
                                        let val = "#NBA"
                                        handleSaveData(getMessageItem(Config.userRole, val))
                                        sendMessage(val, 0)
                                    }}
                                >
                                    #NBA
                                </Button>
                            </div>
                            <SearchBar
                                placeholder='输入名称搜索'
                                ref={searchRef}
                                style={{
                                    '--height': '55px',
                                    marginBottom: "30px"
                                }}
                                clearable
                                onSearch={val => {
                                    if (showCtrlButton) {
                                        return
                                    }
                                    searchRef.current?.clear()
                                    handleSaveData(getMessageItem(Config.userRole, val))
                                    sendMessage(val, 0)
                                }}
                            />
                        </div>
                        <div style={{fontSize: 1}}>
                            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=61011302001889" style={{ color: "gray" }}
                               rel="noreferrer" target="_blank">陕公网安备61011302001889</a>
                            &nbsp;
                            <a href="https://beian.miit.gov.cn/" target="_blank" style={{ color: "gray" }}>陕ICP备2024028916号-1</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default App;