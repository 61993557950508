import React, {useEffect, useState, useRef} from 'react';
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import axios from "axios";
import Hls from 'hls.js';

const App = () => {
    const { share_id, file_id } = useParams();
    const videoRef = useRef(null);
    useEffect(() => {
        let list_resource_req = {
            share_id: share_id,
            file_id: file_id,
        }
        axios.post(`/api/video`, list_resource_req)
            .then(response => {
                let urls = response.data
                let urlInfo = urls[urls.length - 1]
                if (Hls.isSupported()) {
                    const hls = new Hls();
                    hls.loadSource(urlInfo.url);
                    hls.attachMedia(videoRef.current);
                } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                    videoRef.current.src = urlInfo.url;
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (
        <>
            <Helmet>
                <meta name="referrer" content="no-referrer" />
            </Helmet>
            <video ref={videoRef} controls style={{ width: '100vw', height: 'auto' }} />
        </>
    );
};
export default App;