import { marked } from 'marked'

export const tabList = [
    {
        id: "chat-resource",
        name: "资源小助手",
        desc: "",
        avatar: "robot.png"
    },
    {
        id: "chat-gpt",
        name: "ChatGPT",
        desc: "",
        avatar: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=1"
    },
]
export const recordLength = 100
export const chatResourceGuide = marked(`
### 欢迎访问小小资源 (〃'▽'〃) 。  
使用说明：  
1. 在下方输入框输入资源名称，回车🔍搜索；  
2. 搜索结果按顺序⭐️推荐访问；
`)
export const userRole = "user"
export const assistantRole = "assistant"

export function formatDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份是从0开始的
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}