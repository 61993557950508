import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MobileChat from "./mobile/chat/Chat";
import MobileList from "./mobile/list/List";
import Video1 from './mobile/video/Video';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/mobile/video/:share_id/:file_id" element={<Video1 />} />
                <Route path="/mobile/chat" element={<MobileChat />} />
                <Route path="/mobile/list/:share_id/:file_id" element={<MobileList />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;